<template>
  <EndTestBackground :testType="1">
    <b-row>
      <b-col><span class="green">元昊</span> 同学</b-col></b-row
    >
    <b-row>
      <b-col>
        恭喜你完成<span class="green">中考英语冲刺扫雷尖兵关卡</span
        >，你可以开始<span class="green">沙漠行动</span>了
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        你获得：<span class="green">训练答题 关卡奖励</span
        >，已纳入你的后勤补给，继续加油！
      </b-col>
    </b-row>
    <GlobalButton
      buttonType="round"
      buttonText="继续闯关"
      @afterClick="continueSprint"
    ></GlobalButton>
  </EndTestBackground>
</template>

<script>
import GlobalButton from "@/components/global-button/global-button-enter.vue";

import EndTestBackground from "@/components/backgroung/end-test-background.vue";
export default {
  components: {
    GlobalButton,

    EndTestBackground,
  },
  methods: {
    continueSprint() {
      this.$router.replace({
        name: "SmallModuleWordList",
        query: {
          // module: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: #277b24;
}
</style>